const constructMessage = (parameter: string, missingFields: string | string[]): string => {
  let fieldsList: any = missingFields;
  if (typeof missingFields !== 'string') {
    fieldsList = missingFields.join(', ');
  }

  return `Properties "${fieldsList}" for parameter "${parameter}" not provided`;
};

// eslint-disable-next-line import/prefer-default-export
export class MissingPropertyError extends Error {
  constructor(parameter: string, missingFields: string | string[]) {
    super(constructMessage(parameter, missingFields));

    // Set the prototype explicitly.
    Object.setPrototypeOf(this, MissingPropertyError.prototype);
  }
}
