export enum NotificationType {
  Toast = 'toast',
  Alert = 'alert',
}

export enum Position {
  Top = 'top',
  Bottom = 'bottom',
}

export enum Style {
  Info = 'info',
  Success = 'success',
  Warning = 'warning',
  Error = 'error',
  Promo = 'promo',
}

export enum Priority {
  Low = 'low',
  Medium = 'medium',
  High = 'high',
  Urgent = 'urgent',
}

export enum ActionType {
  Close = 'close',
  Forward = 'forward',
  OpenGame = 'openGame',
}

export interface CloseAction {
  type: ActionType.Close,
  title: string,
}

export interface ForwardAction {
  type: ActionType.Forward,
  title: string,
  action: {
    actionIdentifier: string,
    isNavigation?: boolean,
  },
}

export interface GameAction {
  type: ActionType.OpenGame,
  title: string,
  action: {
    gameIdentifier: string,
  },
}

export type Action = CloseAction | ForwardAction | GameAction;

export interface BaseNotification {
  identifier: string;
  title?: string;
  message?: string;
  imageURL?: string | null;
  priority?: Priority;
  primaryAction?: Action | null;
}

export interface Toast extends BaseNotification {
  /*
   * Time in seconds that the toast will be closed.
   * 0 represents it will never be closed. Defaults to 5
  */
  backgroundImageURL?: string | null;
  dismissTime?: number;
  position?: Position;
  style?: Style;
}

export interface Alert extends BaseNotification {
  showClose?: boolean;
  tapToDismiss?: boolean;
  secondaryAction?: Action | null;
  additionalAction?: Action | null;
}
