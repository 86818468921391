import { CalledBeforeInitError, MissingPropertyError } from './errors';
import {
  Alert,
  BaseNotification,
  NotificationType,
  Position,
  Priority,
  Style,
  Toast,
} from './interfaces';
import { logger } from './services';

class RSINotificationHandler {
  private static canPublishEvent(): boolean {
    return rsiApi?.on && RSIEventBus?.publish;
  }

  private static getAppEvents(): AppEvents {
    return rsiApi?.getModule('handlers/AppEvents');
  }

  public static displayAlert(alert: Alert) {
    RSINotificationHandler.publishEvent(
      NotificationType.Alert,
      RSINotificationHandler.prefillAlertDefaults(alert),
    );
  }

  public static displayToast(toast: Toast) {
    RSINotificationHandler.publishEvent(
      NotificationType.Toast,
      RSINotificationHandler.prefillToastDefaults(toast),
    );
  }

  private static prefillDefaults(notification: BaseNotification): Required<BaseNotification> {
    return ({
      identifier: notification.identifier,
      title: notification.title || '',
      message: notification.message || '',
      imageURL: typeof notification.imageURL === 'string' ? notification.imageURL : null,
      priority: notification.priority || Priority.Medium,
      primaryAction: notification.primaryAction || null,
    });
  }

  private static prefillToastDefaults(toast: Toast): Required<Toast> {
    return ({
      ...RSINotificationHandler.prefillDefaults(toast),
      dismissTime: typeof toast.dismissTime === 'number' ? toast.dismissTime : 5,
      position: toast.position || Position.Top,
      style: toast.style || Style.Info,
      priority: toast.priority || Priority.Low,
      backgroundImageURL: typeof toast.backgroundImageURL === 'string' ? toast.backgroundImageURL : null,
    });
  }

  // eslint-disable-next-line no-undef
  private static prefillAlertDefaults(alert: Alert): Required<Alert> {
    return ({
      ...RSINotificationHandler.prefillDefaults(alert),
      showClose: typeof alert.showClose === 'boolean' ? alert.showClose : true,
      tapToDismiss: typeof alert.tapToDismiss === 'boolean' ? alert.tapToDismiss : true,
      secondaryAction: alert.secondaryAction || null,
      additionalAction: alert.additionalAction || null,
    });
  }

  private static publishEvent(eventName: NotificationType.Toast, event: Required<Toast>): void;

  private static publishEvent(eventName: NotificationType.Alert, event: Required<Alert>): void;

  private static publishEvent(
    eventName: NotificationType,
    event: Required<Toast> | Required<Alert>,
  ): void {
    if (!event?.identifier?.length) {
      throw new MissingPropertyError(eventName, 'identifier');
    }

    if (!RSINotificationHandler.canPublishEvent()) {
      throw new CalledBeforeInitError('Alert sent before rsiApi or RSIEventBus has initialized');
    }

    logger.debug('[NOTIFICATIONS] Sending notification event"', {
      notificationType: eventName,
      identifier: event.identifier,
    });

    const notificationData = {
      type: eventName,
      data: event,
    };

    RSIEventBus.publish(RSIEventBus.eventTypes.DISPLAY_NOTIFICATION, notificationData);
    RSINotificationHandler.getAppEvents()
      .send({
        type: 'displayNotification',
        value: notificationData,
      });
  }
}

export default RSINotificationHandler;
